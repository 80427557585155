<template lang="pug" functional>
  v-list-item
    v-avatar.mr-3(
      :size="40"
      :src="props.item.profilePictureUrl")
    v-list-item-content
      v-list-item-title {{ props.item.firstName + ' ' + props.item.lastName }}
      v-list-item-subtitle(
        v-if="props.item.supervisor")
        span.success {{ 'ui.labels.supervisor' | translate }}

</template>

<script>
export default {
  name: 'EmployeesDragItem',

  props: {
    item: Object
  }
}
</script>

<style lang="scss">
</style>
