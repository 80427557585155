<template lang="pug">
  .employees-list
    right-panel-empty-state(
      v-if="!selectedLocation")

    template(v-else)
      .employees-list__header
        h3.title {{ selectedLocation.title }}

      .employees-list__detail
        span
          template(v-if="addedItemsLoaded")
            | {{ employeesCount }} {{'ui.labels._members' | translate}}
        employees-search(
          :added="addedEmployees"
          @add="onItemAdd"
          @remove="onItemRemove")

      .employees-list__actions(
        v-if="selected.length")
        v-checkbox(
          :value="isAllSelected"
          @input="onToggleSelectAll")
        span {{ selected.length }} item(s) selected
        v-dropdown(
          right
          fixed
          auto-close
          width-auto)
          v-btn(
            icon
            gray)
            i.fa.fa-ellipsis-v
          template(slot="content")
            div(style="width:180px")
              v-card(elevation)
                v-list-item(
                  @click="onSelectAll")
                  v-list-item-content
                    v-list-item-title {{ 'ui.labels.select_all' | translate }}
                v-list-item(
                  @click="onUnselectAll")
                  v-list-item-content
                    v-list-item-title {{ 'ui.labels.unselect_all' | translate }}
                v-list-item(
                  @click="onRemoveSelected")
                  v-list-item-content
                    v-list-item-title
                      span.danger {{ 'ui.labels.remove_selected' | translate }}

      v-scrollable(
        :bottom-offset="25"
        @scroll-complete="onNextPage"
         @on-scroll="onScrollList")
        template(v-if="addedItemsLoaded")
          template(
            v-for="item in addedEmployees")
            drag(
              :transfer-data="{ item, selected }"
              @dragstart="onDragStart"
              @dragend="onDragEnd")
              employees-list-item(
                selectable
                :item="item"
                :is-selected="selected.indexOf(item) !== -1"
                @select="onSelect"
                @remove="onItemRemove"
                @close-dropdown-cb="setCloseDropdown")

              template(slot="image")
                employees-drag-list(
                  :items="getDragedItems(item)")

          employee-item-skeleton(
            checkbox
            :count="skeletons")

        template(v-else)
          employee-item-skeleton(
            checkbox
            :count="5")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import EmployeeItemSkeleton from '@/components/common/lists/EmployeeItemSkeleton'

import RightPanelEmptyState from '../../../common/RightPanelEmptyState'

import EmployeesDragList from './EmployeesDragList'
import EmployeesListItem from './EmployeesListItem'
import EmployeesSearch from '../../../organization/common/employees-list/EmployeesSearch'

import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'

export default {
  name: 'EmployeesList',

  mixins: [scrollPaginationSkeletonsMixin],

  components: {
    EmployeeItemSkeleton,
    RightPanelEmptyState,
    EmployeesDragList,
    EmployeesListItem,
    EmployeesSearch
  },

  async mounted () {
    await this.loadEmployees({ isPushed: true })
    this.loadAddedEmployees()
    this.loaded = true
  },

  data: () => ({
    loaded: false,
    addedItemsLoaded: false,
    isAllSelected: false,
    selected: [],
    closeDropdownCb: null
  }),

  methods: {
    ...mapActions('employees', [
      'loadEmployees',
      'searchEmployees'
    ]),

    ...mapActions('locations', [
      'addEmployee',
      'setClipboardItems',
      'removeEmployee',
      'loadLinkedEmployees',
      'activateExternalDragMode',
      'deactivateExternalDragMode'
    ]),

    async loadAddedEmployees () {
      if (this.selectedLocation) {
        this.initScrollPagination(
          this.selectedLocation.data,
          this.loadLinkedEmployees
        )
        this.addedItemsLoaded = false
        await this.loadLinkedEmployees()
        this.addedItemsLoaded = true
      }
      this.selected = []
    },

    onSearch (search) {
      const params = {
        q: search
      }
      this.searchEmployees({ params })
    },

    onSelect (data) {
      if (data.selected) {
        this.selected.push(data.item)
      } else {
        const index = this.selected
          .indexOf(data.item)
        if (index !== -1) {
          this.selected.splice(index, 1)
        }
      }
    },

    onToggleSelectAll (value) {
      if (value) {
        this.onSelectAll()
      } else {
        this.onUnselectAll()
      }
    },

    onSelectAll () {
      for (const item of this.addedEmployees) {
        const index = this.selected
          .indexOf(item)
        if (index === -1) {
          this.selected.push(item)
        }
      }
      this.isAllSelected = true
    },

    onUnselectAll () {
      this.selected = []
      this.isAllSelected = false
    },

    onItemAdd (item) {
      this.addEmployee(item)
    },

    onItemRemove (item) {
      this.removeEmployee(item)
    },

    onRemoveSelected () {
      for (const item of this.selected) {
        this.removeEmployee(item)
      }
    },

    getDragedItems (item) {
      if (this.selected
        .includes(item)) {
        return this.selected
      }
      return [
        ...this.selected,
        item
      ]
    },

    onDragStart (data) {
      const items = this.getDragedItems(data.item)
      this.setClipboardItems(items)
      this.activateExternalDragMode()
    },

    onDragEnd () {
      this.deactivateExternalDragMode()
    },

    setCloseDropdown (cb) {
      this.closeDropdownCb = cb
    },

    onScrollList () {
      if (this.closeDropdownCb) {
        this.closeDropdownCb()
        this.closeDropdownCb = null
      }
    }
  },

  computed: {
    ...mapGetters('employees', [
      'employees'
    ]),

    ...mapGetters('locations', [
      'selectedLocation'
    ]),

    addedEmployees () {
      return this.selectedLocation
        ? this.selectedLocation.data.employees
        : null
    },

    employeesCount () {
      return this.selectedLocation
        ? this.selectedLocation.data.pagination.total
        : this.addedEmployees.length
    },

    employeesItems () {
      return this.employees.map(item => ({
        ...item,
        supervisor: false
      }))
    }
  },

  watch: {
    selectedLocation (value) {
      if (value) {
        this.loadAddedEmployees()
      }
    },

    addedEmployees (value) {
      if (value) {
        this.selected = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .employees-list {
    height: 100%;

    &__header {
      padding: 1rem 1rem 0;
    }

    &__detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 1rem;
      padding-top: .5rem;
    }

    &__actions {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: .5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
