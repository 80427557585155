<template lang="pug">
  v-list
    v-badge(
      v-if="items.length > 1"
      :value="items.length")
      employees-drag-item(
        :item="items[0]")
    employees-drag-item(
      v-else
      :item="items[0]")

</template>

<script>
import EmployeesDragItem from './EmployeesDragItem'

export default {
  name: 'EmployeesDragList',

  props: {
    items: Array
  },

  components: {
    EmployeesDragItem
  }
}
</script>

<style lang="scss">
</style>
