<template lang="pug">
  v-dropdown(
    right
    width-full)
    v-btn.mr-3(
      icon
      gray)
      i.fa.fa-plus
      //- img(src="../../../../../assets/ui/search-icon.svg")
    template(slot="content")
      search-result(
        :value="paginationParams.q"
        :loaded="loaded"
        :items="employees"
        :added="added"
        @search="onSearch"
        @add="onItemAdd"
        @remove="onItemRemove"
        @next="onNextPage")
        template(
          slot="icon"
          slot-scope="{ item }")
          v-avatar.mr-3(
            :size="32"
            :src="item.profilePictureUrl")
        template(
          slot="title"
          slot-scope="{ item }")
          span {{ item.firstName + ' ' + item.lastName }}
        template(
          slot="subtitle"
          slot-scope="{ item }")
          span {{ (item.position && item.position.name) || (item.department && item.department.name) }}

        template(
          slot="list-end")
          employee-item-skeleton(
            small
            :count="skeletons")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SearchResult from '../common/SearchResult'
import EmployeeItemSkeleton from '@/components/common/lists/EmployeeItemSkeleton'

import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'

export default {
  name: 'EmployeesSearch',

  mixins: [scrollPaginationSkeletonsMixin],

  components: {
    SearchResult,
    EmployeeItemSkeleton
  },

  props: {
    added: Array,
    position: Object
  },

  data: () => ({
    loaded: false
  }),

  computed: {
    ...mapGetters('employees', [
      'employees',
      'paginationParams'
    ])
  },

  async mounted () {
    await this.loadEmployees({ isPushed: true })
    this.initScrollPagination(
      { pagination: this.paginationParams },
      this.loadNextPage
    )
    this.loaded = true
  },

  methods: {
    ...mapActions('employees', [
      'loadEmployees',
      'searchEmployees'
    ]),

    async loadNextPage () {
      const page = this.paginationParams.page
        ? this.paginationParams.page + 1
        : 1
      const params = {
        page
      }
      if (this.paginationParams.q) {
        params.q = this.paginationParams.q
        await this.searchEmployees({ params, isPushed: true })
      } else {
        await this.loadEmployees({ params, isPushed: true })
      }
    },

    onSearch (search) {
      const params = {
        q: search,
        page: 1
      }
      this.searchEmployees({ params })
    },

    onItemAdd (item) {
      this.$emit('add', item)
    },

    onItemRemove (item) {
      this.$emit('remove', item)
    }
  }
}
</script>

<style lang="scss">
</style>
